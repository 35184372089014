$black: #1d1019;
/*
#262F31;
*/
body {
  background-color: #FFF;
  color: $black;
  font-size: 16px;
  margin: 0;
  font-family: 'Lato', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

a {
  color: white;
  text-decoration: none;
  &:visited {
    color: white;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-size: 4rem;
  font-weight: 900;
  margin: 0;
  padding: 0;
  @media only screen and (max-width: 600px) {
    font-size: 2.75rem;
  }
}

h2 {
  font-size: 3rem;
  font-weight: 900;
  margin: 0;
  padding: 0;
  @media only screen and (max-width: 600px) {
    font-size: 2.25rem;
  }
}

h3 {
  font-size: 2rem;
  font-weight: 400;
  letter-spacing: 0.25rem;
  text-transform: uppercase;
}